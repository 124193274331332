/* Imports */
@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900,900i&display=swap");
.space-0 {
  margin: 0px !important; }

.space-0-top {
  margin-top: 0px !important; }

.space-0-bottom {
  margin-bottom: 0px !important; }

.space-0-left {
  margin-left: 0px !important; }

.space-0-right {
  margin-right: 0px !important; }

.space-4 {
  margin: 4px !important; }

.space-4-top {
  margin-top: 4px !important; }

.space-4-bottom {
  margin-bottom: 4px !important; }

.space-4-left {
  margin-left: 4px !important; }

.space-4-right {
  margin-right: 4px !important; }

.space-6 {
  margin: 6px !important; }

.space-6-top {
  margin-top: 6px !important; }

.space-6-bottom {
  margin-bottom: 6px !important; }

.space-6-left {
  margin-left: 6px !important; }

.space-6-right {
  margin-right: 6px !important; }

.space-8 {
  margin: 8px !important; }

.space-8-top {
  margin-top: 8px !important; }

.space-8-bottom {
  margin-bottom: 8px !important; }

.space-8-left {
  margin-left: 8px !important; }

.space-8-right {
  margin-right: 8px !important; }

.space-10 {
  margin: 10px !important; }

.space-10-top {
  margin-top: 10px !important; }

.space-10-bottom {
  margin-bottom: 10px !important; }

.space-10-left {
  margin-left: 10px !important; }

.space-10-right {
  margin-right: 10px !important; }

.space-14 {
  margin: 14px !important; }

.space-14-top {
  margin-top: 14px !important; }

.space-14-bottom {
  margin-bottom: 14px !important; }

.space-14-left {
  margin-left: 14px !important; }

.space-14-right {
  margin-right: 14px !important; }

.space-16 {
  margin: 16px !important; }

.space-16-top {
  margin-top: 16px !important; }

.space-16-bottom {
  margin-bottom: 16px !important; }

.space-16-left {
  margin-left: 16px !important; }

.space-16-right {
  margin-right: 16px !important; }

.space-18 {
  margin: 18px !important; }

.space-18-top {
  margin-top: 18px !important; }

.space-18-bottom {
  margin-bottom: 18px !important; }

.space-18-left {
  margin-left: 18px !important; }

.space-18-right {
  margin-right: 18px !important; }

.space-20 {
  margin: 20px !important; }

.space-20-top {
  margin-top: 20px !important; }

.space-20-bottom {
  margin-bottom: 20px !important; }

.space-20-left {
  margin-left: 20px !important; }

.space-20-right {
  margin-right: 20px !important; }

.space-24 {
  margin: 24px !important; }

.space-24-top {
  margin-top: 24px !important; }

.space-24-bottom {
  margin-bottom: 24px !important; }

.space-24-left {
  margin-left: 24px !important; }

.space-24-right {
  margin-right: 24px !important; }

.space-26 {
  margin: 26px !important; }

.space-26-top {
  margin-top: 26px !important; }

.space-26-bottom {
  margin-bottom: 26px !important; }

.space-26-left {
  margin-left: 26px !important; }

.space-26-right {
  margin-right: 26px !important; }

.space-30 {
  margin: 30px !important; }

.space-30-top {
  margin-top: 30px !important; }

.space-30-bottom {
  margin-bottom: 30px !important; }

.space-30-left {
  margin-left: 30px !important; }

.space-30-right {
  margin-right: 30px !important; }

.space-40 {
  margin: 40px !important; }

.space-40-top {
  margin-top: 40px !important; }

.space-40-bottom {
  margin-bottom: 40px !important; }

.space-40-left {
  margin-left: 40px !important; }

.space-40-right {
  margin-right: 40px !important; }

.space-48 {
  margin: 48px !important; }

.space-48-top {
  margin-top: 48px !important; }

.space-48-bottom {
  margin-bottom: 48px !important; }

.space-48-left {
  margin-left: 48px !important; }

.space-48-right {
  margin-right: 48px !important; }

.space-auto {
  margin: auto !important; }

.space-auto-top {
  margin-top: auto !important; }

.space-auto-bottom {
  margin-bottom: auto !important; }

.space-auto-left {
  margin-left: auto !important; }

.space-auto-right {
  margin-right: auto !important; }

.p-0 {
  padding: 0px !important; }

.p-0-top {
  padding-top: 0px !important; }

.p-0-bottom {
  padding-bottom: 0px !important; }

.p-0-left {
  padding-left: 0px !important; }

.p-0-right {
  padding-right: 0px !important; }

.p-4 {
  padding: 4px !important; }

.p-4-top {
  padding-top: 4px !important; }

.p-4-bottom {
  padding-bottom: 4px !important; }

.p-4-left {
  padding-left: 4px !important; }

.p-4-right {
  padding-right: 4px !important; }

.p-6 {
  padding: 6px !important; }

.p-6-top {
  padding-top: 6px !important; }

.p-6-bottom {
  padding-bottom: 6px !important; }

.p-6-left {
  padding-left: 6px !important; }

.p-6-right {
  padding-right: 6px !important; }

.p-8 {
  padding: 8px !important; }

.p-8-top {
  padding-top: 8px !important; }

.p-8-bottom {
  padding-bottom: 8px !important; }

.p-8-left {
  padding-left: 8px !important; }

.p-8-right {
  padding-right: 8px !important; }

.p-10 {
  padding: 10px !important; }

.p-10-top {
  padding-top: 10px !important; }

.p-10-bottom {
  padding-bottom: 10px !important; }

.p-10-left {
  padding-left: 10px !important; }

.p-10-right {
  padding-right: 10px !important; }

.p-14 {
  padding: 14px !important; }

.p-14-top {
  padding-top: 14px !important; }

.p-14-bottom {
  padding-bottom: 14px !important; }

.p-14-left {
  padding-left: 14px !important; }

.p-14-right {
  padding-right: 14px !important; }

.p-16 {
  padding: 16px !important; }

.p-16-top {
  padding-top: 16px !important; }

.p-16-bottom {
  padding-bottom: 16px !important; }

.p-16-left {
  padding-left: 16px !important; }

.p-16-right {
  padding-right: 16px !important; }

.p-18 {
  padding: 18px !important; }

.p-18-top {
  padding-top: 18px !important; }

.p-18-bottom {
  padding-bottom: 18px !important; }

.p-18-left {
  padding-left: 18px !important; }

.p-18-right {
  padding-right: 18px !important; }

.p-20 {
  padding: 20px !important; }

.p-20-top {
  padding-top: 20px !important; }

.p-20-bottom {
  padding-bottom: 20px !important; }

.p-20-left {
  padding-left: 20px !important; }

.p-20-right {
  padding-right: 20px !important; }

.p-24 {
  padding: 24px !important; }

.p-24-top {
  padding-top: 24px !important; }

.p-24-bottom {
  padding-bottom: 24px !important; }

.p-24-left {
  padding-left: 24px !important; }

.p-24-right {
  padding-right: 24px !important; }

.p-26 {
  padding: 26px !important; }

.p-26-top {
  padding-top: 26px !important; }

.p-26-bottom {
  padding-bottom: 26px !important; }

.p-26-left {
  padding-left: 26px !important; }

.p-26-right {
  padding-right: 26px !important; }

.p-30 {
  padding: 30px !important; }

.p-30-top {
  padding-top: 30px !important; }

.p-30-bottom {
  padding-bottom: 30px !important; }

.p-30-left {
  padding-left: 30px !important; }

.p-30-right {
  padding-right: 30px !important; }

.p-40 {
  padding: 40px !important; }

.p-40-top {
  padding-top: 40px !important; }

.p-40-bottom {
  padding-bottom: 40px !important; }

.p-40-left {
  padding-left: 40px !important; }

.p-40-right {
  padding-right: 40px !important; }

.p-48 {
  padding: 48px !important; }

.p-48-top {
  padding-top: 48px !important; }

.p-48-bottom {
  padding-bottom: 48px !important; }

.p-48-left {
  padding-left: 48px !important; }

.p-48-right {
  padding-right: 48px !important; }

.p-auto {
  padding: auto !important; }

.p-auto-top {
  padding-top: auto !important; }

.p-auto-bottom {
  padding-bottom: auto !important; }

.p-auto-left {
  padding-left: auto !important; }

.p-auto-right {
  padding-right: auto !important; }

.text-500 {
  font-weight: 500; }

.text-600 {
  font-weight: 600; }

.text-700 {
  font-weight: 700; }

.text-normal {
  font-weight: normal; }

.text-heading-1 {
  font-size: 48px; }

.text-heading-2 {
  font-size: 36px; }

.text-heading-3 {
  font-size: 28px; }

.text-heading-4 {
  font-size: 18px; }

.text-heading-5 {
  font-size: 16px; }

.text-heading-6 {
  font-size: 14px; }

.text-heading-7 {
  font-size: 12px; }

.text-primary {
  color: #FF7D0A; }

.text-grey-1 {
  color: #E5E5E5; }

.text-grey-2 {
  color: #9C9C9C; }

.text-grey-3 {
  color: #444444; }

.text-grey-4 {
  color: #000000; }

.btn-primary {
  background: #FF7D0A;
  color: color-yiq(#FF7D0A);
  border-color: transparent; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:visited, .btn-primary:active {
    background: #e36a00 !important;
    color: color-yiq(#e36a00);
    border-color: transparent; }
  .btn-primary:disabled, .btn-primary.disabled {
    background: #FF7D0A;
    color: color-yiq(#FF7D0A);
    border-color: transparent; }

.btn-outline-primary {
  background: transparent;
  color: #d66400;
  border-color: #FF7D0A; }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:visited, .btn-outline-primary:active {
    border-color: #e36a00 !important;
    color: #d66400; }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    background: transparent;
    color: #d66400;
    border-color: #FF7D0A; }

.btn-transparent {
  background: transparent;
  color: color-yiq(transparent);
  border-color: transparent; }
  .btn-transparent:hover, .btn-transparent:focus, .btn-transparent:visited, .btn-transparent:active {
    background: rgba(0, 0, 0, 0) !important;
    color: color-yiq(rgba(0, 0, 0, 0));
    border-color: transparent; }
  .btn-transparent:disabled, .btn-transparent.disabled {
    background: transparent;
    color: color-yiq(transparent);
    border-color: transparent; }

.btn-outline-transparent {
  background: transparent;
  color: rgba(0, 0, 0, 0);
  border-color: transparent; }
  .btn-outline-transparent:hover, .btn-outline-transparent:focus, .btn-outline-transparent:visited, .btn-outline-transparent:active {
    border-color: rgba(0, 0, 0, 0) !important;
    color: rgba(0, 0, 0, 0); }
  .btn-outline-transparent:disabled, .btn-outline-transparent.disabled {
    background: transparent;
    color: rgba(0, 0, 0, 0);
    border-color: transparent; }

.container-grey-soft {
  background-color: #F2F2F2; }

.container-white {
  background-color: #ffffff; }

.container-orange-soft {
  background-color: rgba(255, 125, 10, 0.1); }

#navbar .navbar-brand {
  height: 60px;
  padding: 16px 0; }
  #navbar .navbar-brand img {
    height: 32px;
    width: auto; }

#navbar .navbar-inverse {
  border-color: none;
  color: #333333;
  background-color: #ffffff;
  border: 0;
  margin-bottom: 0; }
  #navbar .navbar-inverse .navbar-nav {
    float: none;
    display: flex;
    justify-content: flex-end; }
  #navbar .navbar-inverse .nav > li > a {
    padding: 18px 0 18px 28px;
    color: #333333; }
  #navbar .navbar-inverse .navbar-toggle .icon-bar {
    background-color: #333333; }
  #navbar .navbar-inverse .navbar-toggle:hover {
    background-color: #ffffff; }

@media only screen and (max-width: 760px) {
  #navbar .navbar-inverse .navbar-nav {
    display: block; }
  #navbar .navbar-inverse .nav > li > a {
    padding: 10px 15px;
    color: #333333;
    font-size: 14px; } }

.header-internship {
  margin-top: 50px;
  padding: 10% 5% 10% 5%;
  /* background-image: url("/assets/internship/header.png"); */
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  text-align: center; }
  .header-internship h3 {
    font-weight: bold;
    letter-spacing: 2px; }

.internship {
  text-align: center; }
  .internship .intern-line {
    background-color: #2D9CDB;
    width: 150px;
    height: 4px;
    margin: auto; }
  .internship .title {
    font-weight: bold;
    font-size: 18px; }
  .internship .desc {
    font-size: 14px;
    color: #828282;
    line-height: 16px;
    margin-top: 20px; }
  .internship .row-space {
    margin-top: 35px; }
  .internship .block {
    margin-top: 90px; }
    .internship .block img {
      width: 64px; }
  .internship .col-internship {
    padding: 0 50px; }

@media only screen and (max-width: 786px) {
  .header-internship {
    padding: 15% 0 3% 0; }
    .header-internship h3 {
      font-size: 22px; }
  .internship .block {
    margin: 35px 0 15px; }
    .internship .block img {
      width: 60px; }
  .internship .desc {
    font-size: 12px;
    margin: 10px 20px 30px; }
  .internship .title {
    font-size: 16px; }
  .internship .intern-line {
    width: 130px;
    height: 2px; } }

.footer {
  background-color: #ffffff;
  padding: 20px 0 15px;
  margin-top: 50px; }

.header-mentors {
  margin-top: 50px;
  padding: 10% 5% 10% 5%;
  /* background-image: url("/assets/mentors/header.png"); */
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  text-align: center; }
  .header-mentors h3 {
    font-weight: bold;
    letter-spacing: 2px; }

.mentors .title {
  font-size: 18px;
  text-align: center;
  margin: 50px 0 0; }

.mentors .content-wrapper {
  margin: 0 85px; }
  .mentors .content-wrapper .mentor-wrapper {
    margin: 70px 20px 0; }
    .mentors .content-wrapper .mentor-wrapper .col-wrapper {
      display: flex; }
      .mentors .content-wrapper .mentor-wrapper .col-wrapper .avatar {
        width: inherit;
        height: inherit;
        border: 3px solid #FBC300;
        border-radius: 50%;
        padding: 2px; }
      .mentors .content-wrapper .mentor-wrapper .col-wrapper .avatar img {
        width: 110px;
        height: auto; }
      .mentors .content-wrapper .mentor-wrapper .col-wrapper .quote {
        padding: 15px 33px 15px 70px;
        background-color: #F2F2F2;
        margin-left: -60px;
        z-index: -1;
        width: 100%; }
        .mentors .content-wrapper .mentor-wrapper .col-wrapper .quote p {
          font-size: 12px;
          color: #000000;
          margin: 0; }
    .mentors .content-wrapper .mentor-wrapper .desc-wrapper .name {
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      margin: 15px 0 5px;
      line-height: 19px; }
    .mentors .content-wrapper .mentor-wrapper .desc-wrapper .job {
      color: #828282;
      font-size: 12px;
      line-height: 14px; }

@media only screen and (max-width: 786px) {
  .header-mentors {
    padding: 15% 0 3% 0; }
    .header-mentors h3 {
      font-size: 22px; }
  .mentors .title {
    font-size: 16px;
    margin: 24px 0 20px; }
  .mentors .content-wrapper {
    margin: 0; }
    .mentors .content-wrapper .mentor-wrapper {
      margin: 0 0 40px; }
      .mentors .content-wrapper .mentor-wrapper .col-wrapper {
        align-items: center; }
        .mentors .content-wrapper .mentor-wrapper .col-wrapper .avatar img {
          width: 100px;
          height: auto; }
        .mentors .content-wrapper .mentor-wrapper .col-wrapper .quote {
          padding: 15px 10px 15px 65px;
          min-height: 100px; }
          .mentors .content-wrapper .mentor-wrapper .col-wrapper .quote p {
            font-size: 10px; }
      .mentors .content-wrapper .mentor-wrapper .desc-wrapper .name {
        font-size: 14px;
        margin: 5px 0 0; }
      .mentors .content-wrapper .mentor-wrapper .desc-wrapper .job {
        font-size: 10px;
        line-height: 12px; } }

.header-testimonial {
  margin-top: 50px;
  padding: 10% 5% 10% 5%;
  /* background-image: url("/assets/testimonial/header.png"); */
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  text-align: center;
  height: 100%; }
  .header-testimonial h3 {
    font-weight: bold;
    letter-spacing: 2px; }

.testimonial .title {
  font-size: 18px;
  text-align: center;
  margin: 50px 0 0; }

.testimonial .content-wrapper {
  margin: 0 32%; }
  .testimonial .content-wrapper .box {
    margin: 50px 0; }
    .testimonial .content-wrapper .box img {
      width: 80px;
      height: fit-content; }
    .testimonial .content-wrapper .box .testi-wrapper {
      background-color: #ffffff;
      padding: 11px 13px;
      color: #ffffff;
      border: 1px solid #E0E0E0;
      border-radius: 5px; }
      .testimonial .content-wrapper .box .testi-wrapper .word {
        font-size: 12px;
        color: #4F4F4F; }
      .testimonial .content-wrapper .box .testi-wrapper .name {
        text-align: right;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        color: #828282; }
      .testimonial .content-wrapper .box .testi-wrapper .school {
        font-size: 12px;
        color: #333333;
        text-align: right;
        margin: 0; }

@media only screen and (max-width: 786px) {
  .header-testimonial {
    padding: 15% 0 3% 0; }
    .header-testimonial h3 {
      font-size: 22px; }
  .testimonial .title {
    font-size: 16px;
    margin: 25px 0 0; }
  .testimonial .content-wrapper {
    margin: 0 15px; }
    .testimonial .content-wrapper .box {
      margin: 40px 0; }
      .testimonial .content-wrapper .box img {
        width: 60px;
        height: auto; }
      .testimonial .content-wrapper .box .testi-wrapper {
        padding: 10px; }
        .testimonial .content-wrapper .box .testi-wrapper .word {
          font-size: 11px; }
        .testimonial .content-wrapper .box .testi-wrapper .name {
          font-size: 12px; }
        .testimonial .content-wrapper .box .testi-wrapper .school {
          font-size: 10px; } }

.header-apply {
  margin-top: 50px;
  padding: 10% 0 3% 0;
  background-color: #F2994A;
  color: #ffffff;
  text-align: center; }

.apply {
  margin-top: 50px;
  height: 100%; }
  .apply .apply-frame {
    width: 100%;
    min-height: 240vh;
    overflow: hidden; }

.header-mgmp {
  margin-top: 50px;
  padding: 10% 5% 10% 5%;
  /* background-image: url("/assets/mgmp/header.png"); */
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  text-align: center; }
  .header-mgmp h3 {
    font-weight: bold;
    letter-spacing: 2px; }

.mgmp .mgmp-content {
  padding-top: 30px; }
  .mgmp .mgmp-content .desc-wrapper .title {
    font-size: 18px;
    font-weight: bold;
    margin: 40px 0 20px 0; }
  .mgmp .mgmp-content .desc-wrapper .desc {
    font-size: 16px;
    color: #4f4f4f; }
    .mgmp .mgmp-content .desc-wrapper .desc.link {
      text-decoration: underline;
      color: #F67800; }
  .mgmp .mgmp-content .desc-wrapper .table-mgmp {
    margin-top: 50px;
    font-size: 16px; }
    .mgmp .mgmp-content .desc-wrapper .table-mgmp.silabus {
      margin-top: 30px; }
    .mgmp .mgmp-content .desc-wrapper .table-mgmp table {
      width: 50%;
      border: 1px solid #E0E0E0; }
      .mgmp .mgmp-content .desc-wrapper .table-mgmp table td {
        padding: 15px;
        border: 1px solid #E0E0E0; }
        .mgmp .mgmp-content .desc-wrapper .table-mgmp table td.table-title {
          font-weight: bold; }
        .mgmp .mgmp-content .desc-wrapper .table-mgmp table td.mgmp {
          text-align: center;
          background-color: #EEEEEE;
          font-weight: normal; }
        .mgmp .mgmp-content .desc-wrapper .table-mgmp table td.session {
          width: 20%; }

@media only screen and (max-width: 786px) {
  .mgmp .mgmp-content {
    padding-top: 0; }
    .mgmp .mgmp-content .desc-wrapper .title {
      margin: 30px 0 20px 0;
      font-size: 16px; }
    .mgmp .mgmp-content .desc-wrapper .desc {
      font-size: 14px; }
    .mgmp .mgmp-content .desc-wrapper .table-mgmp, .mgmp .mgmp-content .desc-wrapper .table-mgmp.silabus {
      margin-top: 15px;
      font-size: 14px; }
      .mgmp .mgmp-content .desc-wrapper .table-mgmp table, .mgmp .mgmp-content .desc-wrapper .table-mgmp.silabus table {
        width: 100%; }
        .mgmp .mgmp-content .desc-wrapper .table-mgmp table td.session, .mgmp .mgmp-content .desc-wrapper .table-mgmp.silabus table td.session {
          width: 26%; } }

.soon {
  margin-top: 50px;
  text-align: center; }
  .soon .content {
    margin: 15%; }
    .soon .content .title {
      font-size: 24px;
      margin-bottom: 30px; }
    .soon .content .fa::before {
      font-size: 100px;
      color: #F88829; }
    .soon .content .desc {
      margin-top: 20px;
      font-size: 40px;
      color: #4f4f4f; }

@media only screen and (max-width: 786px) {
  .soon .content {
    margin: 30% 10%; }
    .soon .content .desc {
      font-size: 26px; }
    .soon .content .fa::before {
      font-size: 80px; }
    .soon .content .title {
      font-size: 21px; } }

.flex {
  display: flex; }

.flex--align__center {
  align-items: center; }

.flex--justify__center {
  justify-content: center; }

* {
  margin: 0;
  padding: 0; }

body {
  overflow-x: hidden;
  font-family: 'Lato', sans-serif; }

.flex-align-center {
  display: flex;
  align-items: center; }

.flex-justify-center {
  display: flex;
  justify-content: center; }

.flex-align-justify-center {
  display: flex;
  align-items: center;
  justify-content: center; }
